import {Button, Form, Input} from 'antd';
import styled from 'styled-components';

export const onlyLettersReg = /[A-Za-z]/;
export const onlySpacesReg = /\s/;

export const fetchErrorMessage = (key, errorFieldMessages) => {
  let error;
  if (errorFieldMessages) {
    error = errorFieldMessages?.find(value => {
      if (value[key]) {
        return true;
      }
    });
  }

  return error ? error[key] : null;
};

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 7px;
  width: 442px;
  @media screen and (max-width: 600px) {
    width: 100% !important;
  }
  .ant-form-item-explain-error {
    text-align: start;
  }
  .form-control {
    background-color: #936BDA12 !important;
  }
`;

export const ContinueButton = styled(Button)<{loadingStyle?: string; brandColor?: string}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  background-color: ${p => p.brandColor ? `${p.brandColor} !important` : '#936BDA !important'};
  color: #fff !important;
  border-color: ${p => p.brandColor ? `${p.brandColor} !important` : '#936BDA !important'};
  font-family: inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.91px;
  text-align: left;
  ${p => p.loadingStyle ? p.loadingStyle : ''}
  &:hover, &:focus {
    background-color: ${p => p.brandColor ? `${p.brandColor} !important` : '#63429F !important'};
    color: #fff !important;
    border-color: ${p => p.brandColor ? `${p.brandColor} !important` : '#63429F !important'};
  }
  @media screen and (max-width: 1030px) {
    height: 45px !important;
    font-size: 14px;
  }
  @media screen and (max-width: 800px) {
    height: 40px !important;
    font-size: 12px;
  }
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

export const Terms = styled.div`
  margin: 31px auto;
  max-width: 381px;
  font-family: inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.7;
  a {
    text-decoration: underline;
    color: #FFFFFF;
    /* margin: 0px 5px; */
  }
  div {
    white-space: nowrap;
  }
  @media screen and (max-width: 810px) {
    font-size: 12px !important;
    line-height: 16px;
  }
  @media screen and (max-width: 600px) {
    font-size: 10px !important;
    line-height: 14px;
  }
`;

export const AccountInput = styled(Input)`
width: 100%;
height: 50px !important;
background: #936BDA12 !important;
border: 1px solid #9E85CC69;
color: #FFFFFF !important;
border-radius: 8px !important;
font-family: inter !important;
font-size: 16px !important;
font-weight: 400 !important;
line-height: 22.4px !important;
text-align: left !important;
.ant-input {
  background: transparent !important;
  border: 1px solid #9E85CC69;
  color: #ffff;
}
&:hover, &:focus {
  background: #936BDA12 !important;
  border: 1px solid #9E85CC69;
  border-color: #9E85CC69 !important;
  box-shadow: none !important;
}
&:disabled,
&.ant-input[disabled], .ant-input-affix-wrapper-disabled {
  background-color: rgba(147, 107, 218, 0.07) !important;
  border-color: rgba(158, 133, 204, 0.41) !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

&:-webkit-autofill,
&:-webkit-autofill:hover, 
&:-webkit-autofill:focus, 
&:-webkit-autofill:active  {
    transition: background-color 5000s;
    -webkit-text-fill-color: #FFFF !important;
    background-color: transparent;
}
.ant-input:-webkit-autofill,
.ant-input:-webkit-autofill:hover, 
.ant-input:-webkit-autofill:focus, 
.ant-input:-webkit-autofill:active  {
    transition: background-color 5000s;
    -webkit-text-fill-color: #FFFF !important;
    background-color: transparent !important;
}
@media screen and (max-width: 1030px) {
  height: 45px !important;
  font-size: 14px !important;
  &::placeholder {
    font-size: 14px !important;
  }
.ant-input::placeholder {
  font-size: 14px !important;
}
}
@media screen and (max-width: 810px) {
  height: 40px !important;
  font-size: 12px !important;
  &::placeholder {
    font-size: 12px !important;
  }
.ant-input::placeholder {
  font-size: 12px !important;
}
}
`;

export const FormContainer = styled.div<{brandColor?:string}>`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 556px;
border-radius: 8px;
z-index: 3;
border:  ${p => p?.brandColor ? `1px solid ${p?.brandColor}` : '1px solid #604295'};
background: linear-gradient(158.72deg, rgba(110, 114, 119, 0.08) 3.01%, rgba(40, 42, 45, 0.23) 103.3%);
@media screen and (max-width: 810px) {
  max-width: 556px !important;
  width: 90% !important;
  .ant-form-item-explain-error {
    font-size: 12px;
  }
  .ant-form-item-explain {
    min-height: auto !important;
  }
  input::placeholder {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 600px) {
  .ant-form-item-explain-error {
    font-size: 10px;
  }
}
`;

export const HeadingAnimationWrapper = styled.div`
  position: relative;

  .fade {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in;
    min-width: 100%;
    z-index: 0;
    user-select: none;
    &.visible {
      transition-delay: 0.1s;
      opacity: 1;
      z-index: 1;
    }

    &.hidden {
      opacity: 0;
    }
  }
`;

