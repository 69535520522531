import React, {useState} from 'react';
import styles from './styles.module.scss';
import {Col, Form} from 'antd';
import {AccountInput, ContinueButton, FormContainer, StyledFormItem} from './helpers';
import Image from 'next/image';
import {faArrowLeft, faEye, faEyeSlash} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStore} from '@/store/root-store';
import {linkgraphDomains} from '@/utils/router';
import styled from 'styled-components';
import {getDomain} from '@/utils/url';
import {metaClickEvents} from '@/utils/functions';
import {updateGTMDatalayer} from '@/utils/gtm';

const StepTwo = ({setStepData, stepData}) => {
  const [showPass, setShowPass] = useState(false);
  const [form] = Form.useForm();
  const currentDomain = getDomain(window.location.hostname);
  const isWhiteLabelDomain = !linkgraphDomains.includes(currentDomain);
  const {settings: {customer: {profile: {brandColor, logo, signUpLogo}}}} = useStore('');

  const validatePassword = (_, value) => {
    if (!value.length) {
      return Promise.reject(new Error('Enter a password'));
    }
    if (/\s/.test(value)) {
      return Promise.reject(new Error('Password cannot contain spaces'));
    }
    if (value.length < 8) {
      return Promise.reject(new Error('Use 8 characters or more for your password'));
    }
    return Promise.resolve();
  };

  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <>
      <div className={styles.changeBg}>
        {(isWhiteLabelDomain && brandColor) && (
          <>
            <div className={styles.bgBack}></div>
            <div className={styles.bgFront} style={{'--brand-color': brandColor} as any}></div>
          </>
        )}
        <div
          className={styles.bgGradient}
        />
        <FontAwesomeIcon
          icon={faArrowLeft}
          color='#ffff'
          fontSize={30}
          style={{padding: 20, opacity: 0.5, cursor: 'pointer'}}
          onClick={() => setStepData({step: 'stepOne', data: {...stepData.data}})}
        />
        <div className={styles.formContainer}>
          <div
            style={{height: '22px'}}
          />
          {(isLoading && !isWhiteLabelDomain) && (
            <img
              className={styles.logo}
              style={{width: isWhiteLabelDomain ? '' : '298px', height: 'auto'}}
              src='/img/registerBg/newRegisterLoginLogo.svg'
            />
          )}
          <img className={styles.logo} style={{zIndex: 3, width: isWhiteLabelDomain ? '' : '298px', height: 'auto'}} onLoad={handleImageLoad} src={isWhiteLabelDomain ? (signUpLogo ?? logo ) : '/img/registerBg/newSearchatlasLogo.png'} />
          <div className={styles.stepTwoHeading}>
            <div className={styles.descLineOne}>First, let’s create your account</div>
          </div>
          <FormContainer brandColor={(isWhiteLabelDomain && brandColor) ? brandColor : ''}>
            <div className={styles.formTitle}>Enter a password</div>
            <StyledForm className={styles.form} form={form}
              onFinish={(values: any) => {
                setStepData({step: 'stepThree', data: {...stepData.data, ...values}});
                metaClickEvents('click_register_step_2', {step: 'step 2', password: ''});
                updateGTMDatalayer({'event': 'password_added', 'step': 'step_2', 'password': ''});
              }}
              initialValues={{password: stepData?.data ? stepData?.data?.password : ''}}
            >
              <Col span={24}>
                <Col>
                  <div className={styles.label}>Password</div>
                </Col>
                <StyledFormItem
                  name='password'
                  rules={[
                    {validator: validatePassword},
                  ]}
                >
                  <AccountInput
                    type={showPass ? 'text' : 'password'}
                    placeholder={'Type your password'}
                    suffix={showPass ? <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faEyeSlash} onClick={() => setShowPass(false)} /> : <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faEye} onClick={() => setShowPass(true)} /> }
                  />
                </StyledFormItem>
              </Col>
              <Col>
                <ContinueButton brandColor={(isWhiteLabelDomain && brandColor) ? brandColor : ''} htmlType='submit' style={{marginBottom: '66px'}}>
                  <div>Submit</div>
                  <Image src='/img/registerBg/searchAtlasIcon.svg' width={16} height={16} />
                </ContinueButton>
              </Col>
            </StyledForm>
          </FormContainer>
        </div>
      </div>
    </>
  );
};

export default StepTwo;

export const StyledForm = styled(Form)`
  @media screen and (max-width: 600px) {
    width: 90% !important;
  }
`;
